import { Injectable } from "@angular/core";
import { OrderService } from './OrderService';
import { AnalyticsService } from './AnalyticsService';

@Injectable()
export class searchService {
    pageNO
    clientUniqueID
    constructor(private orderService: OrderService, private analyticsService: AnalyticsService) { }

    searchFilter(searchType, status, county, state, orderType, pageNO): Promise<[]> {
        this.pageNO = pageNO
        var query = {};

        if (status == 'showALL') {
            query = county == "showALL" && orderType == 'showALL' && state == 'showALL' ? {} :
                county == "showALL" && orderType == 'showALL' ? { $and: [{ "property.state": state }] } :
                    orderType == 'showALL' && state == 'showALL' ? { $and: [{ "property.county": county }] } :
                        county == "showALL" && state == 'showALL' ? { $and: [{ "order_type": orderType }] } :
                            county == "showALL" ? { $and: [{ "property.state": state }, { order_type: orderType }] } :
                                orderType == 'showALL' ? { $and: [{ "property.county": county }, { "property.state": state }] } :
                                    state == 'showALL' ? { $and: [{ "order_type": state }, { "property.county": county }] } :
                                        { $and: [{ "property.state": state }, { "property.county": county }, { "order_type": orderType }] }
        }
        else if (county == "showALL") {
            query = orderType == 'showALL' && state == 'showALL' ? { $and: [{ "status": status }] } :
                orderType == 'showALL' ? { $and: [{ "property.state": state }, { "status": status }] } :
                    state == 'showALL' ? { $and: [{ "order_type": orderType }, { "status": status }] } :
                        { $and: [{ "property.state": state }, { "order_type": orderType }, { "status": status }] }

        }
        else if (state == "showALL") {
            query = orderType == 'showALL' ? { $and: [{ "property.county": county }, { "status": status }] } :
                { $and: [{ "order_type": orderType }, { "property.county": county }, { "status": status }] }

        }
        else {
            query = orderType == "showALL" ? { $and: [{ "property.state": state }, { "property.county": county }, { "status": status }] } :
                { $and: [{ "property.state": state }, { "property.county": county }, { "status": status }, { order_type: orderType }] }

        }

        if (!Object.keys(searchType).length) {

        }
        else {
           
            Object.keys(query).length ? query['$and'].push(searchType) : query = searchType
        }
        if (status == "Submitted") {
            query['$and'].map((o, c) => {
                if (o.status) {
                    query['$and'][c].status = { $in: ['Submitted', 'AdminSubmitted'] }
                }
            })

        }
        // return this.getOrderdata(query).then((data: []) => {
        //     return data
        // })
        return

    }

    searchFilterAnlytics(clientUniqueID, status, county, state, orderType, pageNO): Promise<[]> {
        this.pageNO = pageNO
        var query = {};
        this.clientUniqueID = clientUniqueID
        if (status == 'showALL') {
            query = county == "showALL" && orderType == 'showALL' && state == 'showALL' ? {} :
                county == "showALL" && orderType == 'showALL' ? { $and: [{ "property.state": state }] } :
                    orderType == 'showALL' && state == 'showALL' ? { $and: [{ "property.county": county }] } :
                        county == "showALL" && state == 'showALL' ? { $and: [{ "order_type": orderType }] } :
                            county == "showALL" ? { $and: [{ "property.state": state }, { order_type: orderType }] } :
                                orderType == 'showALL' ? { $and: [{ "property.county": county }, { "property.state": state }] } :
                                    state == 'showALL' ? { $and: [{ "order_type": state }, { "property.county": county }] } :
                                        { $and: [{ "property.state": state }, { "property.county": county }, { "order_type": orderType }] }
        }
        else if (county == "showALL") {
            query = orderType == 'showALL' && state == 'showALL' ? { $and: [{ "status": status }] } :
                orderType == 'showALL' ? { $and: [{ "property.state": state }, { "status": status }] } :
                    state == 'showALL' ? { $and: [{ "order_type": orderType }, { "status": status }] } :
                        { $and: [{ "property.state": state }, { "order_type": orderType }, { "status": status }] }

        }
        else if (state == "showALL") {
            query = orderType == 'showALL' ? { $and: [{ "property.county": county }, { "status": status }] } :
                { $and: [{ "order_type": orderType }, { "property.county": county }, { "status": status }] }

        }
        else {
            query = orderType == "showALL" ? { $and: [{ "property.state": state }, { "property.county": county }, { "status": status }] } :
                { $and: [{ "property.state": state }, { "property.county": county }, { "status": status }, { order_type: orderType }] }

        }

        return this.getAnlyticsOrders(query).then((data: []) => {
            return data
        })

    }

    public getOrderdata(query) {
        return this.orderService.getSearchData(query, this.pageNO).toPromise().then(data => {
            return Promise.resolve(data)
        })

    }
    public getAnlyticsOrders(query) {
        return this.analyticsService.getAnalyticsOrders(this.clientUniqueID, query, 1).toPromise().then((o: any) => {
            return o
        })
    }
}