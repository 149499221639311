import { catchError, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Http, RequestOptions, Headers } from "@angular/http";
import { Config } from "../config";
import 'rxjs/Rx';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RefreshService } from './SocketService';
import { Observable } from 'rxjs/Rx';
declare var toastr: any

@Injectable()

export class OrderService {
    configUrl = new Config().apiUrl
    constructor(private http: HttpClient, private refreshService: RefreshService) {
    }
    saveSingleOrderData(singleFormData) {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        const options = new RequestOptions({ headers: myHeaders });
        return this.http.post(this.configUrl + '/order', { order: singleFormData }).pipe(
            catchError(err => {
                toastr.error("Something went wrong")
                throw new Error(err);
            }))
    }

    getOrderData() {
        return this.http.get(this.configUrl + '/order')
    }

    getOrderDetails(orderID) {
        return this.http.get(this.configUrl + '/order/' + orderID)
    }

    getUserActivities() {
        return this.http.get(this.configUrl + '/order/activities')
    }

    getOrderActivities(orderID) {
        console.log("orderID", orderID)
        return this.http.get(this.configUrl + '/order/' + orderID + '/activities')
    }

    saveChatData(orderID, data) {
        return this.http.post(this.configUrl + '/order/' + orderID + '/messages', { message: data })
    }

    getOrderMessages(orderID) {
        return this.http.get(this.configUrl + '/order/' + orderID + '/messages')
    }

    fileUploadwithordernumber(file,nbsOrder: string) {
        var fd = new FormData()
        fd.append('file', file)
        console.log("file file file", file);

        const url = `${this.configUrl}/file?nbsOrder=${encodeURIComponent(nbsOrder)}`;

        return this.http.post(url, fd)
    }

    fileUpload(file) {
        var fd = new FormData()
        fd.append('file', file)
        console.log("file file file", file);

        return this.http.post(this.configUrl + '/file', fd)
    }



    updateOrder(data: any) {
        return this.http.put(this.configUrl + '/order/', data)
    }

    updateStatus(data) {
        let id = data.id
        return this.http.post(this.configUrl + '/order/' + id + '/status', { order: data }).map((data1: any) => {
            // this.refreshService.joinCLientList(data1.client_id)
            // if (data1.assign) {
            //     this.refreshService.joinCLientList(data1.assign.assignee)
            // }
            this.refreshService.postStatusChange(data1)
            return data1
        })
    }

    getStatusCount() {
        return this.http.get(this.configUrl + '/order/statusCount')
    }

    getDisputesStatusCount() {
        return this.http.get(this.configUrl + '/tl/getStatusCountDisputes')
    }

    downloadTemplate(fileName) {
        console.log(this.configUrl + '/file/read?fileName=' + fileName)
        return this.http.get(this.configUrl + '/file/read?fileName=' + fileName)
    }

    saveBatchOrder(batchOrderData) {
        return this.http.post(this.configUrl + '/neworder/createBulkOrder', batchOrderData)
    }

    saveFeedbackData(feedbackData, id) {
        return this.http.post(this.configUrl + '/order/feedback/' + id, feedbackData)
    }

    saveFulfillmentData(fulfillmentData) {
        console.log("fulfilament", fulfillmentData)
        return this.http.post(this.configUrl + '/fulfillment/nonPlusType/', fulfillmentData)
    }

    FulfillmentTokenData() {
        let body = new URLSearchParams();
        body.set('grant_type', 'client_credentials');
        body.set('client_id', '8YotSHEAtCNbi8LO1Nx2yMeWlMCzeNI5');
        body.set('client_secret', 'RQpcFFKzy0QJ_GR2X0B0-d6NROA4eQIRpkGYW0vDUgB-yigpgBgTf042R88FGzPy');
        body.set('audience', 'fulfillment.coviance.com');
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        // return this.http.post('https://uat-login.coviance.com/oauth/token', body.toString(), options)

    }




    saveFulfillmentAddData(orderID, data) {
        return this.http.post('https://uat-api.coviance.com/fulfillments/v1/fulfillment/' + orderID + '/title', data)
    }

    nonPlusDataSend(data) {
        return this.http.post(this.configUrl + '/fulfillment/sendFulfillmentDataToClient?id=3/', data)
    }

    PlusDataSend(data,fulfillmentid) { 
        console.log("#################### fulfilmet",fulfillmentid);
         
        return this.http.post(this.configUrl + '/fulfillment/sendFulfillmentDataToClient?id=' + fulfillmentid, data)
    }


    getFulfillmentData(orderID) {
        return this.http.get(this.configUrl + '/fulfillment/nonPlusType/' + orderID)   
    }




    saveFulfillmentPlusTypeData(fulPlusTypeData) {
       console.log("fulPlusTypeData fulPlusTypeData",fulPlusTypeData);
       
        return this.http.post(this.configUrl + '/fulfillment/plusType/', fulPlusTypeData) 
    }

    saveFulfillmentPlusTypeDatafilr(nbOrderREF,orderstatus_value) {    
        
        // return this.http.get(this.configUrl + '/file/ejs/' + nbOrderREF, {responseType: 'text'})
          return this.http.get(this.configUrl + '/file/ejs/' + nbOrderREF + '/' + orderstatus_value, {responseType: 'text'})
     }




    
    getFulfillmentPlusTypeData(orderID,orderstatus_value) {
        return this.http.get(this.configUrl + '/fulfillment/plusType/' + orderID +'/' + orderstatus_value)
    }
    clarify(data) {
        return this.http.post(this.configUrl + '/order/clarify/', data).map((data1: any) => {
            // this.refreshService.joinCLientList(data1.client_id)
            // if (data1.assign) {
            //     this.refreshService.joinCLientList(data1.assign.assignee)
            // }
            this.refreshService.postStatusChange(data1)
            return data1
        })
    }
    onTypeSearch(cond) {
        return this.http.post(this.configUrl + '/order/search/', cond)
    }

    getBatchOrders() {
        return this.http.get(this.configUrl + '/order/batch')
    }

    getAllNotifications(page) {
        return this.http.get(this.configUrl + '/order/getEmpNotification', { params: { pageNO: page } })
    }

    getNotificationCount(query) {
        return this.http.get(this.configUrl + '/order/getActivityCount', { params: { query: JSON.stringify(query) } })
    }

    MarkNotificationsAsRead() {
        return this.http.post(this.configUrl + '/order/updateNotification', {})
    }

    assignOrder(assignData) {
        return this.http.post(this.configUrl + '/assign/', { assign: assignData })
    }

    getAssignOrder(pageNo, query) {
        return this.http.get(this.configUrl + '/assign/', { params: { pageNo: pageNo, query: JSON.stringify(query) } })
    }

    // getAllAssignOrder(pageNo) {
    //     return this.http.post(this.configUrl + '/assign/getnewAssignOrders', { 'pageNo': pageNo })
    // }

    getAllUnAssignOrder(data) {
        console.log(data)
        return this.http.post(this.configUrl + '/neworder/fetchMasterQueueNewOrder', data)
    }

    orderAssign(assignData) {
        return this.http.post(this.configUrl + '/neworder/assignOrder', assignData)
    }

    orderDelete(data) {
        console.log("delete order numberrr delete order numberrr delete order numberrr", data);
        return this.http.post(this.configUrl + '/neworder/deleteOrdersBasedOnCond', data)
    }

    teamleaderOrderAssign(assignData) {
        console.log(assignData)
        return this.http.post(this.configUrl + '/tl/assignMultipleOrders', assignData)
    }

    editSavedata(editedData){
        console.log("edited datata edited dataa",editedData);
        
        return this.http.post(this.configUrl + '/neworder/updateOrderBasedOnNBSID', editedData)

    }

    teamleaderOrderUnAssign(assignData) {
        console.log(assignData)
        return this.http.post(this.configUrl + '/tl/unAssignMultipleOrders', assignData)
    }

    getMasterQueueSearchOrder(p, query) {
        console.log({ params: { query: JSON.stringify(query), pageNO: p } })
        return this.http.get(this.configUrl + '/neworder/getPartialNewOrders', { params: { query: JSON.stringify(query), pageNO: p } })
    }

    getClientsAndStages(obj) {
        return this.http.post(this.configUrl + '/neworder/clientStageDropdown', obj)

    }

    getMasterQueueFilters(p, query) {
        console.log({ params: { query: JSON.stringify(query), pageNO: p } })
        return this.http.get(this.configUrl + '/neworder/filterPartialNewOrders', { params: { query: JSON.stringify(query), pageNO: p } })
    }

    getMasterFiltersData(data) {
        console.log("filter post data", data);
        return this.http.post(this.configUrl + '/neworder/filterOrdersBasedOnCond', data)
    }

    saveExpenses(orderID, expensesData, total) {
        return this.http.post(this.configUrl + '/order/expense', { expense: { order_id: orderID, expense: expensesData, total: total } })
    }

    getExpenses(orderID) {
        return this.http.get(this.configUrl + '/order/expense/' + orderID)
    }

    updateBatchOrder(batchID) {
        return this.http.put(this.configUrl + '/order/batch', batchID)
    }

    savePlottedFulfillment(data) {
        return this.http.post(this.configUrl + '/order/plottedEasements', data)
    }

    getPlottedFulfillment(orderID) {
        return this.http.get(this.configUrl + '/order/plottedEasements/' + orderID)
    }

    // getClientOrders() {
    //     return this.http.get(this.configUrl + '/order', { params: this.email })
    // }

    getOrderFeedback(orderID) {
        return this.http.get(this.configUrl + '/order/feedback/' + orderID)  
    }

    saveMaster(data) {
        return this.http.post(this.configUrl + '/manage/master/', data)
    }

    getMaster(type) {
        return this.http.get(this.configUrl + '/manage/master/', { params: { type: JSON.stringify(type) } })
    }

    getCostinfo(data){
        return this.http.post(this.configUrl + '/manage/getMastersBasedOnCond', data)
    }

    getStages(type) {
        return this.http.post(this.configUrl + '/manage/getstagebystageid', type)
    }

    getSearchData(query, p) {
        return this.http.get(this.configUrl + '/order/selectedSearch/', { params: { query: JSON.stringify(query), pageNO: p } })
    }

    saveInvoice(data) {
        return this.http.post(this.configUrl + '/invoice/create', data)
    }

    getInvoices(p, query) {
        return this.http.get(this.configUrl + '/invoice/get', { params: { query: JSON.stringify(query), pageNO: p } })
    }

    getInvoiceExpense(orderID) {
        return this.http.get(this.configUrl + '/invoice/getInvoiceExpense', { params: { order_id: orderID } })
    }

    despatchInvoice(orderID) {
        return this.http.get(this.configUrl + '/invoice/dispatch/' + orderID)
    }

    saveFinalInvoice(data) {
        return this.http.post(this.configUrl + '/invoice/finalInvoice', data)
    }

    updateInvoice(id) {
        return this.http.post(this.configUrl + '/invoice/update', id)
    }
    joinRoom() {
        return this.http.get(this.configUrl + '/auth/joinRoom')
    }

    // clearQAOrders() {
    //     return this.http.post(this.configUrl + '/neworder/clearAllMasterBinQABin', {
    //         "is_cleared": true})
    // }

    clearQAOrders(data) {
        return this.http.post(this.configUrl + '/neworder/clearAllMasterBinQABin', data)
    }

    generateReport(query) {
        console.log('query', query);

        return this.http.get(this.configUrl + '/neworder/reportGenerateNewOrders', { params: { query: JSON.stringify(query) } })
    }

    OperationReport(query) {
        console.log('query', query);
        return this.http.get(this.configUrl + '/neworder/operationalMasterreportNewOrders', { params: { query: JSON.stringify(query) } })
    }

    deleteFile(file) {
        return this.http.delete(this.configUrl + '/file/delete?fileName=' + file)
    }


    deleteuploadFile(file) {
        return this.http.post(this.configUrl + '/file/deleteFilesAwsAndDB', file)
    }


    getAllStage() {
        return this.http.get(this.configUrl + '/manage/getAllstages')
    }

    getAllOldMessages(data) {  
        return this.http.post(this.configUrl + '/order/fetchMessagesBasedOnOrderId', data)
    }

    getOrderbypage(data) {
        return this.http.post(this.configUrl + '/neworder/getclientOrders/', data)
    }

    getOrderDetailsbyOrderId(data) {
        return this.http.post(this.configUrl + '/order/clientIdNameBasedOnOrderId/', data)
    }

    AcceptorDeclinedOrder(data) {
        return this.http.post(this.configUrl + '/tl/actionOnClientCreatedOrders/', data)
    }
    FulfillmentStatus(data) {
        return this.http.post(this.configUrl + '/fulfillment/status/submit/', data)
    }
    getAllFiles(orderData) {
        console.log("orderrrr", orderData)
        return this.http.post(this.configUrl + '/order/getFiles', orderData)
    }

    getInvoicesSystemData(data) {
        return this.http.post(this.configUrl + '/neworder/getAllCompletedOrders', data)
    }
    saveInvoiceSystem(data) {
        return this.http.post(this.configUrl + '/fulfillment/insertInvoiceSystem', data)
    }
}