import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes=[
  
  // { 
  //   path:'login', 
  //   component: LoginComponent
  // },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: '**', redirectTo: 'home' },
  { 
    path:'home', 
    loadChildren: './pages/home/home.module#HomeModule'
  },
  { 
    path:'login', 
    loadChildren: './pages/login/login.module#LoginModule'
  },
  { 
    path:'otpvalidation', 
    loadChildren: './pages/otpvalidation/otpvalidation.module#LoginModules'
  },
  // {
  //   path:'firstTimeLogin',
  //   component: FirstTimeLoginComponent
  // },
  // {
  //   path:'resetPassword',
  //   component: ResetPasswordComponent
  // } 
]
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
