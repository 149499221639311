import * as io from 'socket.io-client';
import { Config } from '../config';
import { Observable } from 'rxjs/Observable';
import * as Rx from 'rxjs/Rx';
import { Injectable } from '@angular/core';
const url = new Config().url
// const config: SocketIoConfig = { url: url, options: {} };
 const socket = io(url);

 @Injectable({
    providedIn: 'root'  // <- ADD THIS
})

export class ChatService {
    // private url = 'http://localhost:3000';
    orderId
    _socket

    constructor() {
        this._socket = io(url);
    }
    public  joinRoom(roomId) {
        // return new Promise(res => {
        this._socket.emit('joinRoom', roomId)
        //     res()
        // })
    }

    public getMessages = () => {
        return Observable.create((observer) => {
            this._socket.on('message', (message) => {
                observer.next(message);
            });
            // socket.disconnect()
        });
    }
   

    public sendMessage(message, user_id, receiver_id) {          
        this.orderId = message.order_id
        console.log("MESSSAGE",message);  
        
        this._socket.emit('greeting' , { userId : user_id , socketId : this._socket.id });
        this._socket.emit('message' , {sender: user_id, receiver: receiver_id, message: message});

        // this._socket.on('connect', () => {
        //     this._socket.emit('greeting' , { userId : user_id , socketId : this._socket.id });
        //     this._socket.emit('message' , {sender: user_id, receiver: receiver_id, message: message});
        // });

        // this._socket.on('message', data => {
        //     document.write(JSON.stringify(data))
        //     console.log('Msg Data : ', data);
        // })
    }
    // leaveRoom(roomId){
    //     thissocket.emit('leaveRoom', roomId)
    // }

    

}

export class RefreshService {
    _socket
    selectedOrders
    constructor() {
        this._socket = io(url);
        this.selectedOrders = []
      //  this._socket.on('disconnect', () => {
      //  })

    }
    public joinRoom(id) {
        this._socket.emit('joinOrderList', id)
    }

    leaveRoom(id) {
        this._socket.emit('leaveOrderList', id)
    }
    refershOrdersList(room) {
        this._socket.emit('refershOrdersList', room)
    }

    public getRefershOrders = () => {
        return Observable.create((observer) => {
            this._socket.on('refereshOrders', (token) => {
                observer.next(token);
            });
            // socket.disconnect()
        });
    }

    public getAssignOrders = () => {
        return Observable.create((observer) => {
            this._socket.on('assignOrder', (token) => {
                observer.next(token);
            });
            // socket.disconnect()
        });
    }

    disableAssignOrder(orders: any) {
        this.selectedOrders.push(orders)
        this._socket.emit('disableAssignOrder', orders)
    }

    refershAssignPageOrder() {
        this._socket.emit('refershAssignPageOrder')
    }

    getAssignPageOrders = () => {
        return Observable.create((observer) => {
            this._socket.on('getAssignPageOrder', (orders) => {
                observer.next(orders);
            });
            // socket.disconnect()
        });
    }

    getDisableOrders = () => {
        return Observable.create((observer) => {
            this._socket.on('getDisableOrders', (orders) => {
                observer.next(orders);
            });
            // socket.disconnect()
        });
    }

    enableOrders = (data) => {
        // return Observable.create((observer) => {
        this._socket.emit('enable', (data))
        // socket.disconnect()
        // });
    }
    disableOrders = (data) => {
        // return Observable.create((observer) => {
        this._socket.emit('disable', (data))
        // socket.disconnect()
        // });
    }

    joinCLientList(data) {
        this._socket.emit('joinCLientList', data)
    }
    postStatusChange(data) {
        this._socket.emit('postStatusChange', data)
    }

    public statusChange = () => {
        return Observable.create((observer) => {
            this._socket.on('statusChange', (data) => {
                observer.next(data);
            });
            // socket.disconnect()
        });
    }
    public getActvity = () => {
        return Observable.create((observer) => {
            this._socket.on('getActivity', (data) => {

                observer.next(data);
            });
            // socket.disconnect()
        });
    }


}