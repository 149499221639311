import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
declare var toastr: any
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(private router: Router,  private ngxUiLoader: NgxUiLoaderService,) { 
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem("access_token");
    if (idToken) {
      let cloned;
      if(req.url == 'https://uat-login.coviance.com/oauth/token'){
        cloned = req.clone({});
      }else{
        cloned = req.clone({
          headers: req.headers.set("Authorization",
            idToken)
        });
      }

      return next.handle(cloned).pipe(
        // map((event: HttpEvent<any>) => {
        //   if (event instanceof HttpResponse) {
        //     console.log('event--->>>', event);
        //   }
        //   return event;
        // })
        catchError((error: HttpErrorResponse) => {
          let data = {};
          data = {
            reason: error && error.error.reason ? error.error.reason : '',
            status: error.status
          };
          if (error.error.error) {
          }
          else if (error.error) {
            if (error.error == "JwtExpire") {
              // toastr.error("Session Expired")
              this.router.navigate(['login'])
            }
            else
            this.ngxUiLoader.stopAll()
              toastr.error(error.error)          
          }
          else
            toastr.error("something went wrong")
          // this.router.navigate(['login'])
          // return next.handle(req)
          throw error
        })
      )
    }

    else {

      return
      // return next.handle(req);
    }
  }
}